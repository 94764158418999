import PostImg1 from "../assets/images/resources/postImg2-1.jpg";
import PostImg2 from "../assets/images/resources/postImg2-2.jpg";
import PostImg3 from "../assets/images/resources/postImg2-3.jpg";
import PostImg4 from "../assets/images/resources/postImg2-4.jpg";
import PostImg5 from "../assets/images/resources/postImg2-5.jpg";
import PostImg6 from "../assets/images/resources/postImg2-6.jpg";

export const posts2 = [
  {
    id: 1,
    postImg: PostImg1,
    postDate: "21",
    postMonth: "January",
    postDateLink: "#",
    postTitle: "Spring Which I Enjoy With For Effective Agile Team",
    postLink: "#",
    postAuthor: "Berline Tokyo",
    postAuthorLink: "#",
    cateList: [
      {
        cateItem: "Design,",
        cateLink: "#",
      },
      {
        cateItem: "Technology,",
        cateLink: "#",
      },
      {
        cateItem: "Events",
        cateLink: "#",
      },
    ],
  },
  {
    id: 2,
    postImg: PostImg2,
    postDate: "15",
    postMonth: "June",
    postDateLink: "#",
    postTitle: "But some of the current projects that make",
    postLink: "#",
    postAuthor: "John Doe",
    postAuthorLink: "#",
    cateList: [
      {
        cateItem: "Design,",
        cateLink: "#",
      },
      {
        cateItem: "Events,",
        cateLink: "#",
      },
      {
        cateItem: "Technology",
        cateLink: "#",
      },
    ],
  },
  {
    id: 3,
    postImg: PostImg3,
    postDate: "21",
    postMonth: "January",
    postDateLink: "#",
    postTitle: "Project that began allow second phase in worth",
    postLink: "#",
    postAuthor: "Berline Tokyo",
    postAuthorLink: "#",
    cateList: [
      {
        cateItem: "Design,",
        cateLink: "#",
      },
      {
        cateItem: "Technology,",
        cateLink: "#",
      },
      {
        cateItem: "Events",
        cateLink: "#",
      },
    ],
  },
  {
    id: 4,
    postImg: PostImg4,
    postDate: "15",
    postMonth: "June",
    postDateLink: "#",
    postTitle: "Multiethnic colleagues sitting at desk looking",
    postLink: "#",
    postAuthor: "John Doe",
    postAuthorLink: "#",
    cateList: [
      {
        cateItem: "Design,",
        cateLink: "#",
      },
      {
        cateItem: "Events,",
        cateLink: "#",
      },
      {
        cateItem: "Technology",
        cateLink: "#",
      },
    ],
  },
  {
    id: 5,
    postImg: PostImg5,
    postDate: "21",
    postMonth: "January",
    postDateLink: "#",
    postTitle: "Shot of two work colleage using a digital tablet",
    postLink: "#",
    postAuthor: "Berline Tokyo",
    postAuthorLink: "#",
    cateList: [
      {
        cateItem: "Design,",
        cateLink: "#",
      },
      {
        cateItem: "Technology,",
        cateLink: "#",
      },
      {
        cateItem: "Events",
        cateLink: "#",
      },
    ],
  },
  {
    id: 6,
    postImg: PostImg6,
    postDate: "15",
    postMonth: "June",
    postDateLink: "#",
    postTitle: "Beautiful Specialist with Short Pink Hair Talks",
    postLink: "#",
    postAuthor: "John Doe",
    postAuthorLink: "#",
    cateList: [
      {
        cateItem: "Design,",
        cateLink: "#",
      },
      {
        cateItem: "Events,",
        cateLink: "#",
      },
      {
        cateItem: "Technology",
        cateLink: "#",
      },
    ],
  },
];
