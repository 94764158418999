export const education = [
  {
    eduDate: "2016 - 2019",
    eduTitle: "Harverd University",
    eduIns: "Harverd University",
    eduDesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    eduDate: "2013 - 2015",
    eduTitle: "Honours of UI/UX",
    eduIns: "Cambridge University",
    eduDesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    eduDate: "2008 - 2012",
    eduTitle: "Bachelor of Science",
    eduIns: "TM Primary School",
    eduDesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    eduDate: "2005 - 2008",
    eduTitle: "School Certificate",
    eduIns: "TM Primary School",
    eduDesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
];
